
import { Alert, Button, Descriptions, Modal, Space, Table, Tag, Typography } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { useSelector } from 'react-redux';
import { currencyFormat, numFormat } from '../../Utils/formatCurrency';
import { UnorderedListOutlined } from '@ant-design/icons';

function ListMembership() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [load, setLoad] = useState(0);
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [detail, setDetail] = useState(null);
    const [transData, setTransData] = useState([]);
    const [sort, setSort] = useState({
        sortField: 'id',
        sortOrder: 'DESC',
    });

    // //Parameter
    const titlePage = 'User';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: false,
            render: (text, record, index) => (page.pagination.current - 1) * page.pagination.pageSize + index + 1,
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            sorter: true,
        },
        {
            title: 'Nomor Telp',
            dataIndex: 'mobile',
            key: "mobile",
            sorter: false,
            render: (text, record) => (record.mobile ? `0${record.mobile}` : null),
        },
        {
            title: 'Point',
            dataIndex: 'PointBalance',
            key: "PointBalance",
            sorter: true,
            render: (text, record) =>
                record.PointWallet ? `${numFormat(record.PointWallet.PointBalance)} Point` : `0 Point`,
        },
        {
            title: 'Role',
            dataIndex: 'Roles',
            key: "Roles",
            sorter: false,
            render: (text, record) => (record.MS_Role ? record.MS_Role.Roles : '-'),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => getApi('/user/' + record.id, 'detail')}>
                            <UnorderedListOutlined />
                            <b style={{ marginLeft: 5 }}>Detail</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        const { current, pageSize } = page.pagination;
        const { sortField, sortOrder } = sort;
        const url = `/user?role=3&search=${search}&page=${current}&limit=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`;
        getApi(url, 'all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        JSON.stringify(page),
        isAlert,
        load,
        // page.pagination.current, page.pagination.pageSize, sort.sortField, sort.sortOrder
    ]);

    const getApi = (url, type) => {
        setLoading(true);

        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'all':
                        var item = res.data.list;
                        setData(item);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                // total: res.data.count
                                total: res.data.pagination.total,
                            }
                        });
                        setLoading(false);
                        break;
                    case 'detail':
                        var item = res.data;
                        setTransData(item.transactions);
                        setDetail(item);
                        setIsModal(true);
                        setLoading(false);
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }


    // Handle table change
    const handleTableChange = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;
        const sortField = sorter.field || 'id';
        const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';

        setPage((prev) => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current,
                pageSize,
            },
        }));

        setSort({ sortField, sortOrder });
        setLoad(load + 1);
    };

    const searchData = (value) => {
        setSearch(value);
        setLoad(load + 1);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const closeModal = () => {
        setIsModal(false);
        setDetail(null);
        setTransData();
        setLoading(false);
    }

    const handleSearch = (value) => {
        const txSearch = value.toLowerCase();
        const filteredTransactions = detail.transactions.filter((transaction) =>
            transaction.transaction_code.toLowerCase().includes(txSearch)
        );
        setTransData(filteredTransactions)
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => closeModal(false)} />}
            <h3>Daftar Membership</h3>
            <Search
                placeholder="Pencarian"
                allowClear
                enterButton="Search"
                size="medium"
                onSearch={searchData}
                style={{ marginBottom: 10, width: 300 }}
            />
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />

            <Modal
                title="Detail Transaksi"
                open={isModal}
                onCancel={closeModal}
                footer={null}
                width={800}
            >
                {
                    detail && detail !== null ?
                        <>
                            {/* Informasi Customer */}
                            <Descriptions title="Informasi Pelanggan" bordered column={2} size="small">
                                <Descriptions.Item label="Kode Customer">{detail.code_customer || "-"}</Descriptions.Item>
                                <Descriptions.Item label="Nama">{detail.name}</Descriptions.Item>
                                <Descriptions.Item label="Email">{detail.email || "-"}</Descriptions.Item>
                                <Descriptions.Item label="Telepon">{detail.phone}</Descriptions.Item>
                                <Descriptions.Item label="Total Poin">
                                    <Tag color="blue">{Number(detail.point).toLocaleString()} Poin</Tag>
                                </Descriptions.Item>
                            </Descriptions>

                            <Space style={{ marginTop: 20, marginBottom: 10, width: "100%" }}>
                                <Search
                                    placeholder="Cari Kode Transaksi..."
                                    onChange={(e) => handleSearch(e.target.value)}
                                    enterButton
                                    allowClear
                                />
                            </Space>
                            <div style={{ maxHeight: 400, overflowY: "auto", paddingRight: 10 }}>
                                {console.log(transData)}
                                {transData.length > 0 && transData.map((transaction, index) => (
                                    <div key={index} style={{ marginTop: 20 }}>
                                        <Typography.Title level={5}>Transaksi: {transaction.transaction_code}</Typography.Title>
                                        <Descriptions bordered column={2} size="small">
                                            <Descriptions.Item label="Total Belanja">
                                                Rp {Number(transaction.grand_total).toLocaleString()}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Metode Pembayaran">{transaction.payment_method}</Descriptions.Item>
                                            <Descriptions.Item label="Alat Pembayaran">{transaction.device_payment}</Descriptions.Item>
                                            <Descriptions.Item label="Voucher">
                                                Rp {Number(transaction.voucher_price).toLocaleString()}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Poin Digunakan">
                                                <Tag color="red">{currencyFormat(Number(transaction.point_redeem))} Poin</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Poin Diperoleh">
                                                <Tag color="green">{currencyFormat(Number(transaction.point?.point) || 0)} Poin</Tag>
                                            </Descriptions.Item>
                                        </Descriptions>

                                        {/* Tabel Produk */}
                                        <Table
                                            dataSource={transaction.transaction_products}
                                            columns={[
                                                {
                                                    title: "Nama Produk",
                                                    dataIndex: ["product", "product_name"],
                                                    key: "product_name",
                                                },
                                                {
                                                    title: "Harga",
                                                    dataIndex: "price",
                                                    key: "price",
                                                    render: (text) => currencyFormat(Number(text)),
                                                },
                                                {
                                                    title: "Qty",
                                                    dataIndex: "qty",
                                                    key: "qty",
                                                },
                                                {
                                                    title: "Subtotal",
                                                    dataIndex: "subtotal",
                                                    key: "subtotal",
                                                    render: (text) => currencyFormat(Number(text)),
                                                },
                                            ]}
                                            size='small'
                                            pagination={false}
                                            style={{ marginTop: 10 }}
                                            rowKey={(record) => record.product.product_name}
                                            bordered
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                        :
                        null
                }
            </Modal>
        </>
    );
}

export default ListMembership;

